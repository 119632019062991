import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import WebclientProvider from './Libs/Webclient';
import StorageProvider from './Libs/StorageProvider';
import AuthProvider from './Libs/AuthProvider';
import AlertProvider from './Libs/AlertProvider';
const AppRouter = React.lazy(() => import('./AppRouter'));

export default function App() {
    return (
        <React.Suspense fallback={<></>}>
            <StorageProvider>
            <WebclientProvider>
            <AlertProvider>
            <AuthProvider>
                <Router>
                    <AppRouter/>
                </Router>
            </AuthProvider>
            </AlertProvider>
            </WebclientProvider>
            </StorageProvider>
        </React.Suspense>
    );
}
