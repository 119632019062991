import React from 'react';
import axios from 'axios';

import { PREFIX_API } from '../Configuration';

const WebclientContext = React.createContext();

export const WebclientURL = {
    login: PREFIX_API + '/user/login',
    signup: PREFIX_API + '/user/signup',
    reset: PREFIX_API + '/user/reset',
    newpassword: PREFIX_API + '/user/newpassword',

    admin_login: PREFIX_API + '/mouseadmin/login',
    admin_reset: PREFIX_API + '/mouseadmin/reset',
    admin_newpassword: PREFIX_API + '/mouseadmin/newpassword',

    articles_all: PREFIX_API + '/articles',
    articles: PREFIX_API + '/articles/tags',
    article: PREFIX_API + '/article/(title_slug)',
    article_by_id: PREFIX_API + '/article/id/(id)',
    article_update: PREFIX_API + '/article/(id)',
    article_create: PREFIX_API + '/article',
    article_upload_file: PREFIX_API + '/article/(id)/files',
    article_delete_file: PREFIX_API + '/article/(id)/files/delete',

    tags: PREFIX_API + '/tags?sections=(sections)&lang=ru',
};

const AUTHORIZATION_HEADER = 'Authorization';

export default function WebclientProvider({children}) {
    const webclient = axios.create({
        baseURL: PREFIX_API,
        timeout: 5000,
        validateStatus: (status) => {
            if ((status >= 200) && (status < 300)) return true;
            if ((status >= 400) && (status < 500)) return true;
            return false;
        },
    });

    const doPost = ({url, data, timeout}) => {
        return webclient.post(url, data, {timeout: timeout || 5000});
    };

    const doGet = ({url, data}) => {
        const newUrl = !!data ? url + '?' + new URLSearchParams(data).toString() : url;
        return webclient.get(newUrl);
    };

    const setAuthorization = (token) => {
        const headers = webclient.defaults.headers;
        if (token) {
            headers.common[AUTHORIZATION_HEADER] = 'Bearer ' + token;
        } else {
            headers.common = Object.fromEntries(
                Object.entries(headers.common).filter((item) => item[0] !== AUTHORIZATION_HEADER)
            );
        }
    };

    const values = {
    };

    const funcs = {
        post: doPost,
        get: doGet,
        setAuthorization: setAuthorization,
    };

    return (
        <WebclientContext.Provider value={{...values, ...funcs}}>
            {children}
        </WebclientContext.Provider>
    )
}

export const useWebclient = () => React.useContext(WebclientContext);
