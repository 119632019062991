import React from 'react';

import { AlertTriangle, Info, XOctagon } from 'react-feather';

import { AlertModal } from '../templates/AlertModal';

const AlertContext = React.createContext();

export default function AlertProvider({children}) {
    const [alertModal, setAlertModal] = React.useState({
        title: '',
        titleIcon: null,
        message: '',
        buttons: [null, null],
        size: 'md',
        cbClose: null,
        cbConfirm: null,
    });
    const [alertData, setAlertData] = React.useState();

    const openModal = (modalInfo, ...modalData) => {
        setAlertModal({...alertModal, ...modalInfo});
        if (!!modalData && modalData.length === 1) {
            setAlertData(modalData[0]);
        } else {
            setAlertData(modalData);
        }
    }

    const closeModal = () => {
        openModal({title: '', message: '', buttons: [null, null]}, null);
    }

    const setSize = (size) => {
        setAlertModal({...alertModal, ...{size: size}});
    }

    const onButtonConfirm = (toolId) => {
        if (alertModal.cbConfirm) {
            alertModal.cbConfirm(toolId, alertData);
        }
    }

    const onButtonClose = () => {
        closeModal();
        if (alertModal.cbClose) {
            alertModal.cbClose();
        }
    }

    const showError = (message) => {
        showMessage({
            titleIcon: <XOctagon size={25} color="red"/>,
            title: 'Error',
            message: message,
        });
    }

    const showWarning = (message) => {
        showMessage({
            titleIcon: <AlertTriangle size={25} color="yellow"/>,
            title: 'Warning',
            message: message,
        });
    }

    const showInfo = (message) => {
        showMessage({
            titleIcon: <Info size={25} color="green"/>,
            title: 'Info',
            message: message,
        });
    }

    const showMessage = (messageInfo) => {
        openModal({
            titleIcon: messageInfo.titleIcon || null,
            title: messageInfo.title || 'Info',
            size: 'md',
            message: messageInfo.message,
            buttons: ['Close', null],
        });
    }

    const values = {
        alertModal: alertModal,
        data: alertData,
    };

    const funcs = {
        showError: showError,
        showWarning: showWarning,
        showInfo: showInfo,
        openModal: openModal,
        closeModal: closeModal,
        setData: setAlertData,
        setSize: setSize,
    };

    return (
        <AlertContext.Provider value={{...values, ...funcs}}>
            <AlertModal
                size={alertModal.size}
                titleIcon={alertModal.titleIcon}
                title={alertModal.title}
                message={alertModal.message}
                parameter={alertModal.parameter}
                onConfirm={onButtonConfirm}
                onClose={onButtonClose}
                buttonCancel={alertModal.buttons && alertModal.buttons[0]}
                buttonOK={alertModal.buttons && alertModal.buttons[1]}
            />
            {children}
        </AlertContext.Provider>
    )
}

export const useAlert = () => React.useContext(AlertContext);
