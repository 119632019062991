import React from 'react';

import { DictStrStr, TagType } from '../Types';

interface IStorageContext {
    tagsDB: {[key: string]: {[key: string]: TagType}},
    tagsData: TagType[],
    addTags(tags: TagType[]): any,
    clearTags(): any,
}

const StorageContextDefaults = {
    tagsDB: {byName: {}, byId: {}},
    tagsData: [],
    addTags: () => {},
    clearTags: () => undefined,
}

type StorageProviderProps = {
    children: any,
}

const StorageContext = React.createContext<IStorageContext>(StorageContextDefaults);

export default function StorageProvider({children}: StorageProviderProps) {
    const [tagsDB, setTagsDB] = React.useState<{[key: string]: {[key: string]: TagType}}>({
        byId: {},
        byName: {},
    });
    const [tagsData, setTagsData] = React.useState<TagType[]>([]);

    const clearTags = () => {
        setTagsDB({
            byId: {},
            byName: {},
        });
        setTagsData([]);
    }

    const addNewTags = (tags: TagType[]) => {
        const newTagsById: {[key: string]: TagType} = {...tagsDB.byId};
        const newTagsByName: {[key: string]: TagType} = {...tagsDB.byName};

        tags.forEach((tag) => {
            newTagsById[tag.id] = tag;
            newTagsByName[tag.name] = tag;
        });

        const newTagsDB = {
            byId: newTagsById,
            byName: newTagsByName,
        }
        setTagsDB(newTagsDB);

        return newTagsDB;
    }

    const values = {
        tagsDB: tagsDB,
        tagsData: tagsData,
    }

    const funcs = {
        addTags: addNewTags,
        clearTags: clearTags,
    }

    return (
        <StorageContext.Provider value={{...values, ...funcs}}>
            {children}
        </StorageContext.Provider>
    )
}

export const useStorage = () => React.useContext(StorageContext);
