export const PREFIX_API = 'https://dev.mousemath.org/api/1';
export const PREFIX_FILES_API = 'https://dev.mousemath.org/files';
export const PREFIX = '';
export const PREFIX_ADMIN = '/mouseadmin';

export const PREFS = {
    SESSION_ID: 'mm_sid',
    USER: 'mm_user',
}

export const CATEGORIES_PRELOAD:[string, [string, number][]][] = [
    ['По возрасту', [
        ['До 4 лет', 1],
        ['4-5 лет', 1],
        ['5-6 лет', 1],
        ['6-7 лет', 1],
        ['1 класс', 1],
        ['2 класс', 1],
        ['3 класс', 1],
    ]],
    ['По темам', [
        ['Словесные', 1],
        ['Логические', 1],
        ['Арифметические', 1],
        ['Геометрические', 1],
    ]],
    ['Ещё', [
        ['Мыш учёный', 1],
        ['Мыш арифметика', 1],
        ['Соедини по количеству', 1],
        ['Голодный крокодил', 1],
        ['Брось кубик и раскрась', 1],
        ['Зайцы за забором', 1],
        ['Счётные таблицы', 1],
    ]]
];
