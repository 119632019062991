import React from 'react';

import { Button, Modal, Spinner } from 'react-bootstrap';

// https://github.com/react-bootstrap/react-bootstrap/issues/5075
const modalAnimation = false;

export const AlertModal = ({size, titleIcon, title, message, parameter, onConfirm, onClose, buttonOK, buttonCancel}) => {
    const onModalClose = () => {
        if (onClose) {
            onClose(parameter);
        } else if (onConfirm) {
            onConfirm(parameter);
        }
    }

    const onModalConfirm = () => {
        if (onConfirm) {
            onConfirm(parameter);
        } else if (onClose) {
            onClose(parameter);
        }
    }

    return (
        <Modal show={!!message} onHide={onModalClose} animation={modalAnimation} size={size || 'md'}>
            <Modal.Header closeButton={true}>
                <Modal.Title>
                    <span className="" style={{opacity: 0.7}}>{titleIcon}</span>
                    <span className="pl-2">{title || 'Info'}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {React.isValidElement(message) ?
                message
                :
                String(message).split('\n').map((messageLine, idx) => (
                    <span key={idx}>
                        {messageLine}<br/>
                    </span>
                ))
            }
            </Modal.Body>
            <Modal.Footer>
                {buttonCancel !== null ?
                    <Button variant="secondary" onClick={onModalClose}>{buttonCancel || 'Close'}</Button>
                    : null
                }
                {buttonOK !== null ?
                    <Button variant="primary" onClick={onModalConfirm}>
                        {buttonOK === 0 ?
                            <>
                                <Spinner
                                    className="mx-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="sr-only">Loading...</span>
                            </> :
                            (buttonOK || 'OK')
                        }
                    </Button>
                    : null
                }
            </Modal.Footer>
        </Modal>
    )
}